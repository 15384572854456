import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/markaz/1.png';
import pic2 from '../assets/images/markaz/2.png';
import pic3 from '../assets/images/markaz/3.png';
import f1 from '../assets/images/feature1.jpg';
import f2 from '../assets/images/feature2.jpg';
import logo from '../assets/images/logo.png';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>

        <div className="top-buttons">
          <a className="button primary fit " a href="/Donate" aria-label="Donate">
            Help build a Masjid!
          </a>
          <a className="button small fit" a href="https://calendly.com/admin-7737/booking-an-event" aria-label="Schedule">
            Schedule a viewing
          </a>
        </div>
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a
            href="https://www.youtube.com/watch?v=_nFgpor_LAw&feature=youtu.be"
            target="_blank"
            className="image"
          >
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Quran focused Islamic education</h2>
            <p>
              At Al-Itqaan center, we strive to live up to our name. Our goal is
              to teach The Quran and all the sciences related to the recitation,
              understanding, and preservation of the Quran. But don't just take
              it from us, take a look at some of our students demonstrating what
              they've learned so far.
            </p>
            <a
              href="https://www.youtube.com/watch?v=_nFgpor_LAw&feature=youtu.be"
              target="_blank"
              className="special"
            >
              Watch
            </a>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a
            href="https://www.youtube.com/watch?v=cKejMXDysY0&feature=youtu.be"
            target="_blank"
            className="image"
          >
            <img src={pic2} alt="" />
          </a>
          <div className="content">
            <h2 className="minor">Student Testimonials</h2>
            <p></p>
            <a
              href="https://www.youtube.com/watch?v=cKejMXDysY0&feature=youtu.be"
              target="_blank"
              className="special"
            >
              Watch
            </a>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" target="_blank" className="image">
            <img src={pic3} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Quality Education</h2>
            <p>
              We provide the twin cities and students from across Minnesota with
              a quality Islamic education. Our qualified teachers teach all the
              sciences a student will need to properly recite and apply the
              Quran. We cater to most age groups and both genders.
            </p>
            <a href="/#" target="_blank" className="special">
              View classes
            </a>
          </div>
        </div>
      </section>

      <section id="four" className="wrapper alt style1">
        <div className="inner">
          <h2 className="major">New property acquisition</h2>
          Al-Itqaan Center is in the process of acquiring a church for use as a
          Masjid!
          <section className="features">
            <article>
              <a href="/Gallery" className="image">
                <img src={f1} alt="" />
              </a>
              <p>View our most recent photos of the new property.</p>
            </article>
            <article>
              <a
                href="https://youtu.be/Hs0RrgpgAs0"
                target="_blank"
                className="image"
              >
                <img src={f2} alt="" />
              </a>
              <p>Watch a short tour of the new property.</p>
            </article>
          </section>
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
